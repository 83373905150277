/* home page custom design */

/* preloader */
#preloader {
    background-color: #17c5ff;
  }


/* main button styling */
.main-btn.btn-filled {
    background-color: #17c5ff;
    border-color: #17c5ff;
}

.main-btn.btn-borderd:hover {
    background-color: #17c5ff;
    border-color: #17c5ff;
}

header.header-three .mainmenu-area .main-right .main-btn.btn-filled:hover {
    border-color: #17c5ff;
    background-color: #fff;
}

.banner-section .single-banner .banner-text .btn-wrap a.main-btn.btn-borderd:hover {
    color: #041d38;
    border-color: #17c5ff;
}

.main-btn::before {
    width: 10px;
    height: 2px;
}

.main-btn::after, .main-btn::before {
    position: absolute;
    right: 10px;
    top: 10px;
    content: '';
    background-color: #17c5ff;
    transition: all 0.3s ease-out 0s;
}

/* service section */
.services-secton .services-loop .single-service.primary-bg {
    background-color: #17c5ff;
}

.services-secton .services-loop .single-service.secondary-bg .service-link {
    color: #17c5ff;
}

.services-secton .services-loop .single-service.doted::after {
    left: auto;
    top: auto;
    bottom: 25px;
    right: -25px;
    background: linear-gradient(90deg, #041d38 50%, #17c5ff 50%);
}

.section-title span.title-tag {
    text-transform: uppercase;
    font-weight: 700;
    color: #17c5ff;
    font-family: "Oswald", sans-serif;
    line-height: 1;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
}
.section-title.left-border span.title-tag::before {
    background-color: #17c5ff;
}

.services-secton .services-loop .single-service-two .top-icon {
    color: #17c5ff;
}
.services-secton .services-loop .single-service-two .bottom-icon{
    color: #17c5ff;
}
.services-secton .services-loop .single-service-two:hover {
    border-color: #17c5ff;
}

/* portfolio section */
.portfolio-slider-section .portfolio-slider-loop .portfolio-box .portfolio-desc .portfolio-cat {
    color: #17c5ff;
}

.portfolio-slider-section .portfolio-slider-loop .portfolio-box .portfolio-desc .portfolio-cat::before {
    background-color: #17c5ff;
}

.portfolio-slider-section .portfolio-slider-loop .portfolio-box .portfolio-desc .portfolio-link {
    background-color: #17c5ff;
}


/* cta section */
.call-to-action.cta-style-two .cta-inner .cta-text.diff-bg .section-title span.title-tag {
    color: #17c5ff;
}

.call-to-action.cta-style-two .cta-inner .cta-text.diff-bg .section-title span.title-tag::before {
    background-color: #17c5ff   ;
}

.call-to-action.cta-style-two .cta-inner .cta-text.diff-bg .cat-link {
    background-color: #17c5ff;
}

.call-to-action.cta-style-two .cta-inner .cat-img .cat-shape.shape-two::before {
    background-color: #17c5ff;
}

/* contact us section */
.contact-form-wrapper .contact-info-list .info-box .icon {
    color: #17c5ff;
}

.input-group .icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    color: #17c5ff;
}

/* footer section */
footer.footer-style-three .footer-widget-area .widget a:hover {
    color: #17c5ff;
}

footer.footer-style-three .footer-top .social-icon a:hover {
    background-color: #17c5ff;
}

footer.footer-style-three .copy-right-area a:hover {
    color: #17c5ff;
}

/* about page custom design */
.breadcrumb-section .breadcrumb-nav {
    background: #17c5ff;
}

.services-secton.services-secton-three::after {
    background-color: #17c5ff;
}

.framework-section .framework-text .framework-list .nav-item a:hover span.icon, .framework-section .framework-text .framework-list .nav-item a.active span.icon {
    background-color: #17c5ff;
    color: #072341;
}

.framework-section .framework-text .framework-list .nav-item a span.icon {
    color: #17c5ff;
}

.counter-section .counter-inner {
    background-color: #17c5ff;
}

/* services page custom design */
.features-boxes-two .features-boxes-loop .feature-box-two .icon i {
    background-color: #17c5ff;
}

.section-title.both-border span.title-tag::before, .section-title.both-border span.title-tag::after {
    background-color: #ffaa17;
}

.testimonials-slider-two .single-testimonial-two::before{
    background-color: #17c5ff;
}

.testimonials-slider-two .single-testimonial-two .testimonial-author .author-info span{
    color: #17c5ff;
}

.services-secton .services-loop .single-service-two h4{
    min-height: 58px;
}

.services-secton .services-loop .single-service-two p{
    min-height: 45px;
}

.mobile-nav:hover {
    background-color: #17c5ff;
    cursor: pointer;
    color: #fff;
}

.mobile-nav a {
    color: #000;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
}